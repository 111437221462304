import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "@yisheng90/react-loading";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import styles from "./Projects.module.css";
import Project from "./Project";

const Projects = () => {
  const [repos, setRepos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    axios.get(`https://api.github.com/users/jamesshah/repos`).then((res) => {
      res.data.sort(
        (a, b) => parseInt(b.stargazers_count) - parseInt(a.stargazers_count)
      );
      setRepos(res.data);
      setTimeout(function () {
        setIsLoading(false);
      }, 1000);
    });
  }, []);

  return (
    <section className={styles.projects}>
      <div className={styles.projectTable}>
        <a href="https://github.com/jamesshah">
          <h1 className={styles.projectTitle}>
            Projects <Arrow />
          </h1>
        </a>
        <div className={`${styles.tableRow} ${styles.projectRowHeader}`}>
          <div className={styles.projectTitleHeader}>Name</div>
          <div className={styles.projectDescriptionHeader}>Description</div>
          <div className={styles.projectStarsHeader}>Star</div>
          <div className={styles.projectGithub}>Repository</div>
          <div className={`${styles.ProjectLive} ${styles.projectLiveHeader}`}>
            Visit
          </div>
        </div>

        {isLoading ? (
          <>
            {repos.map((repo) => (
              <Skeleton
                width="100%"
                height="44px"
                color="#111111"
                translucent
                style={{ marginBottom: "10px" }}
              />
            ))}
          </>
        ) : (
          repos.map((repo) => <Project key={repo.id} data={repo} />)
        )}
      </div>
    </section>
  );
};

export default Projects;
