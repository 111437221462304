import React from "react";

import styles from "./Projects.module.css";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import { ReactComponent as Star } from "../../icons/star.svg";

const Project = ({ data }) => (
  <>
    <div className={styles.tableRow} key={data.id}>
      <div className={styles.projectTitleHeader}>{data.name}</div>
      <div className={styles.projectDescriptionHeader}>{data.description}</div>
      <div className={styles.projectStarsHeader}>
        <Star />
        {data.stargazers_count}
      </div>
      <div className={styles.projectGithub}>
        {data.html_url ? (
          <a target="_blank" rel="noopener noreferrer" href={data.html_url}>
            Github <Arrow />
          </a>
        ) : (
          ""
        )}
      </div>
      <div className={styles.projectLive}>
        {data.homepage ? (
          <a target="_blank" rel="noopener noreferrer" href={data.homepage}>
            Live <Arrow />
          </a>
        ) : (
          ""
        )}
      </div>
    </div>
  </>
);

export default Project;
