import React, { useState, useEffect } from "react";
import axios from "axios";

import styles from "./Header.module.css";
import { ReactComponent as Html } from "../../icons/html.svg";
import { ReactComponent as Css } from "../../icons/css.svg";
import { ReactComponent as JavaScript } from "../../icons/javascript.svg";
import { ReactComponent as Python } from "../../icons/python.svg";
import { ReactComponent as ReactJs } from "../../icons/react.svg";
import { ReactComponent as Express } from "../../icons/express.svg";
import { ReactComponent as Arrow } from "../../icons/arrow.svg";
import { ReactComponent as Github } from "../../icons/github.svg";
import { ReactComponent as Java } from "../../icons/java.svg";

const Header = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    axios.get("https://api.github.com/users/jamesshah").then((res) => {
      setUser(res.data);
    });
  }, []);
  if (!user) {
    return "";
  }
  return (
    <header className={styles.headerWrapper}>
      <div className={styles.aboutMe}>
        <div className={styles.aboutMeWrapper}>
          <img
            className={styles.aboutMeImg}
            src={user.avatar_url}
            alt="james-shah"
          />
          <div className={styles.aboutMeDetails}>
            <h1 className={styles.name}>{user.name}</h1>
            <h2 className={styles.description}>{user.bio}</h2>
            <div className={styles.skills}>
              <Java />
              <Python />
              <Html />
              <Css />
              <JavaScript />
              <Express />
              <ReactJs />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactMe}>
        <a href={user.html_url} target="blank">
          Github Profile
          <Github />
        </a>
        <a href="mailto:jdshah18072000@gmail.com">
          Contact
          <Arrow />
        </a>
      </div>
    </header>
  );
};

export default Header;
