import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Projects from "./components/Projects/Projects";
// import Posts from "./components/Posts/Posts";

function App() {
  return (
    <div className="App">
      <Header />
      <Projects />
      {/* <Posts /> */}
    </div>
  );
}

export default App;
